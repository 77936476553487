import { ILocationDetails } from 'interfaces/location.interface';
import { ITag } from './tag.interface';
import { UserTypes } from 'constants/user';

interface IGetUser {
  id: number;
  emailAddress: string;
  name: string;
}

export type { IGetUser };

export interface IUserDetails {
  aadObjectId: string;
  avatar: string;
  emailAddress: string;
  id: number;
  isAdmin: boolean;
  isLocked: boolean;
  isSuperUser: boolean;
  linkedInId: string;
  locations?: ILocationDetails;
  userType: UserTypes;
  secondaryEmail: string;
  description?: string;
}

export interface IUserDetailsWithTags extends IUserDetails {
  tags: ITag[];
}

export enum DomainNames {
  'h2Digital' = '@h2-digital.com',
}
