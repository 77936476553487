import { commonConstants } from 'constants/common';
import HttpClient from '../httpClient/httpClient';
import { encryptMessage } from 'utils/EncryptUtil';
import { LocationType } from 'constants/location';
import { ILocationMiniInfo } from 'interfaces/location.interface';

class LocationService {
  private azureAdScope = commonConstants.AZURE_AD_SCOPE;

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async getAllLocationByType(h2DigitalType?: LocationType[]) {
    const queryParams: Record<string, string | undefined> = {};

    if (h2DigitalType) {
      queryParams.type = h2DigitalType.join(',');
    }

    return this.httpClient
      .get(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/location/all/locations`,
        {
          params: queryParams,
        }
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async getAllLocationsForAdminCenterStartPage() {
    return this.httpClient
      .get(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/location/all/locations/short`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async deleteLocation(locationId: string) {
    return this.httpClient
      .delete(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/location/delete-location/${locationId}`
      )
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async addNewLocation(data: any) {
    try {
      const newLocation = await this.httpClient.post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/location/add-new-location/`,
        data
      );
      return newLocation;
    } catch (error) {
      console.log('add Location failed', error);
    }
  }

  async autoComplete(searchText: string, signal: AbortSignal): Promise<any> {
    let query = searchText + '/' + new Date().getTime();
    query = encryptMessage(query);
    //changed to non auth api to use in partner-join page
    return this.httpClient.get(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/geo?query=` +
        encodeURIComponent(query),
      { signal }
    );
  }

  async getLocationDetails(locationId: number) {
    const result = await this.httpClient.get(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/location/details/${locationId}`
    );
    return result.data;
  }

  async updateLocationDetails(locationId: number, locationData: any) {
    try {
      const response = await this.httpClient.patch(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/location/admin/update-project/${locationId}`,
        locationData
      );
      return response;
    } catch (error) {
      console.log('LocationDetails update failed', error);
      throw error;
    }
  }

  async findNames(): Promise<ILocationMiniInfo[]> {
    return this.httpClient
      .get(`${process.env.REACT_APP_H2_DIGITAL_API_URL}/location/all/names`)
      .then((response: { data: any }) => {
        const result = response.data;
        return result;
      });
  }

  async uploadImage(file: string | Blob, locationId: string) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/location/admin/image/${locationId}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.projectImage;
  }

  async uploadLogo(file: string | Blob, id: number) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/location/admin/logo/${id}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.logo;
  }
}

const SLocationService = new LocationService();
export default SLocationService;
