import {
  Autocomplete,
  MenuItem,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { ILocationMiniInfo } from 'interfaces/location.interface';
import React from 'react';

interface LocationAutocompleteProps {
  locationList: ILocationMiniInfo[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectedLocationChange: (
    event: React.SyntheticEvent,
    value: ILocationMiniInfo | null
  ) => void;
  sx?: SxProps<Theme>;
  defaultValue?: ILocationMiniInfo;
  value?: ILocationMiniInfo;
  isOptionEqualToValue?: (
    option: ILocationMiniInfo,
    value: ILocationMiniInfo
  ) => boolean;
  label?: string;
}

const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({
  locationList,
  onSelectedLocationChange,
  sx,
  defaultValue,
  isOptionEqualToValue,
  value,
  label,
}) => {
  return (
    <Autocomplete
      disablePortal
      id="location-autocomplete"
      options={locationList}
      sx={{ ...{ width: 300 }, ...sx }}
      getOptionLabel={(option) => option?.name || ''}
      onChange={onSelectedLocationChange}
      defaultValue={defaultValue}
      isOptionEqualToValue={isOptionEqualToValue}
      value={value}
      renderOption={(props, option) => {
        return (
          <MenuItem {...props} key={option?.id} value={option?.id}>
            <div>{option?.name}</div>
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={label ?? 'Location*'} />
      )}
    />
  );
};

export default LocationAutocomplete;
