import LocationAutocomplete from 'components/LocationAutocomplete/LocationAutocomplete';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { InitialUserDetails, mapUserType } from 'constants/user';
import React, { Dispatch, SyntheticEvent } from 'react';
import { FunctionComponent } from 'react';
import { ILocationMiniInfo } from 'interfaces/location.interface';
import { IUserDetails } from 'interfaces/user.interface';

interface IEditUserDetails {
  editUserDialogue: boolean;
  handleCloseEdit: () => void;
  selectedUser: Partial<IUserDetails>;
  setSelectedUser: Dispatch<React.SetStateAction<Partial<IUserDetails>>>;
  emailError: string;
  setEmailError: Dispatch<React.SetStateAction<string>>;
  locationsList: ILocationMiniInfo[];
  userLocation: ILocationMiniInfo | undefined;
  setUserLocation: Dispatch<
    React.SetStateAction<ILocationMiniInfo | undefined | null>
  >;
  handleUpdateUserDetails: () => void;
}

interface InputType {
  name: string;
  label: string;
}

export const EditUserDetails: FunctionComponent<IEditUserDetails> = ({
  editUserDialogue,
  handleCloseEdit,
  selectedUser,
  setSelectedUser,
  emailError,
  setEmailError,
  locationsList,
  userLocation,
  setUserLocation,
  handleUpdateUserDetails,
}) => {
  const checkBoxes: InputType[] = [
    { name: 'isAdmin', label: 'Admin' },
    { name: 'isLocked', label: 'Locked' },
    { name: 'isSuperUser', label: 'Super User' },
  ];

  const handleCancel = () => {
    setEmailError('');
    setSelectedUser(InitialUserDetails);
    handleCloseEdit();
  };

  const handleCheckBoxValueChange = <Key extends keyof IUserDetails>(
    property: Key
  ) => {
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [property]: !prevUser[property],
    }));
  };

  const handleSelectedUserProjectChange = (
    e: SyntheticEvent<Element, Event>,
    location: ILocationMiniInfo | null
  ) => {
    setUserLocation(location ?? undefined);
    if (location)
      setSelectedUser((prevUser) => ({
        ...prevUser,
        projectId: location.id,
      }));
  };

  return (
    <Dialog
      open={editUserDialogue}
      onClose={handleCloseEdit}
      className="user-edit-form"
    >
      <DialogTitle>{'Edit User Details'}</DialogTitle>
      <DialogContent>
        <FormGroup className="checkbox-container">
          {checkBoxes.map((checkBox, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={Boolean(
                      selectedUser[checkBox.name as keyof IUserDetails]
                    )}
                    onChange={() => {
                      handleCheckBoxValueChange(
                        checkBox.name as keyof IUserDetails
                      );
                    }}
                  />
                }
                label={checkBox.label}
              />
            );
          })}
        </FormGroup>
        <TextField
          label="Secondary Email"
          required
          sx={{ width: '100%' }}
          error={emailError !== '' ? true : false}
          helperText={emailError || ''}
          type="text"
          value={selectedUser.secondaryEmail || ''}
          onChange={(event: { target: { value: string } }) => {
            setEmailError('');
            setSelectedUser({
              ...selectedUser,
              secondaryEmail: event.target.value,
            });
          }}
        />
        <div className="user-type-select">
          <FormControl>
            <InputLabel id="user-type-select-label">
              {'Select User Type'}
            </InputLabel>
            <Select
              required
              id="user-type-select"
              value={selectedUser.userType}
              label={'Select User Type'}
              onChange={(event: any) =>
                setSelectedUser((prevUser) => ({
                  ...prevUser,
                  userType: event.target.value,
                }))
              }
            >
              {mapUserType?.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <FormControl required sx={{ mb: 5, width: '100%' }}>
          <LocationAutocomplete
            locationList={locationsList}
            sx={{ width: '100%' }}
            onSelectedLocationChange={handleSelectedUserProjectChange}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            value={userLocation}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <div className="button-wrapper">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" onClick={handleUpdateUserDetails}>
            Update User
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
